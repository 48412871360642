/* styles.scss */

/* From Uiverse.io by Yaya12085 */

/* Variables */
$border-color: #cacaca;
$icon-color: rgba(75, 85, 99, 1);
$background-color: rgba(255, 255, 255, 1);
$box-shadow-color: rgba(0, 0, 0, 0.1);

/* Mixins */
@mixin center-flex {
  display: flex;
  align-items: center;
//   justify-content: center;
}

/* Main Styles */
.custum-file-upload {
  height: 60px;
  display: flex;
  flex-direction: column;
//   gap: 50px;
padding: 0 25px!important;
  cursor: pointer;
//   align-items: center;
  justify-content: center;
  border: 0px dashed $border-color;
  background-color: $background-color;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px $box-shadow-color;

  .icon {
    @include center-flex;
    
    svg {
      height: 60px;
      fill: $icon-color;
    }
  }

  .text {
    @include center-flex;

    span {
      font-weight: 400;
      color: $icon-color;
    }
  }

  input {
    display: none;
  }
}
