.custom-mobile-paste-button {
    position: relative;
    display: block;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .custom-mobile-paste-container-button {
    background-color: #ffffff;
    color: #212121;
    // padding: 10px 15px;
    padding: 0.5vmax 1vmax;
    font-size: 15px;
    width: 100%;
    font-weight: bold;
    border: 2px solid transparent;
    // border-radius: 15px;
    cursor: pointer;
  }
  
  .custom-mobile-paste-container-dropdown-content {
    display: none;
    font-size: 13px;
    // position: absolute;
    z-index: 1;
    // width: 100%;
    background-color: #f4f5f6;
    border: 2px solid #f4f5f6;
    // border-radius: 0px 15px 15px 15px; 
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    overflow-x: scroll;
  }
  
  .custom-mobile-paste-container-dropdown-content a {
    color: black;
    padding: 8px 10px;
    text-decoration: none;
    display: block;
    transition: 0.1s;
  }
  
  .custom-mobile-paste-container-dropdown-content a:hover {
    background-color: #ffffff;
    color: #212121;
  }
  
  .custom-mobile-paste-container-dropdown-content a:focus {
    background-color: #ffffff;
    color: #4CAF50;
  }
  
  .custom-mobile-paste-container-dropdown-content #top:hover {
    border-radius: 0px 13px 0px 0px;
  }
  
  .custom-mobile-paste-container-dropdown-content #bottom:hover {
    border-radius: 0px 0px 13px 13px;
  }
  
  .custom-mobile-paste-button:hover .custom-mobile-paste-container-button {
    border-radius: 15px 15px 0px 0px;
  }
  
  .custom-mobile-paste-button:hover .custom-mobile-paste-container-dropdown-content {
    display: block;
  }