.wave-text {
    font-size: 15px;
    font-weight: bold;
    display: inline-block;
    background-image: linear-gradient(90deg, #f8e2e2, #ffe4b3, #d8f3dc, #b3d4ff, #dab3ff);
    background-size: 400%;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: waveAnimation 9s linear infinite;
  
    @keyframes waveAnimation {
      0% {
        background-position: 0%;
      }
      100% {
        background-position: 400%;
      }
    }
  }
  